import './style.css'
import * as THREE from 'three'
//import * as dat from 'dat.gui'

// Texture loader
const loader = new THREE.TextureLoader()
const height = loader.load('/textures/displacement_map.jpg')
const texture = loader.load('/textures/texture.jpg')
const alpha = loader.load('/textures/alpha_map.png')

// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Objects
const geometry = new THREE.PlaneBufferGeometry(4, 4, 32, 32)

// Materials
const material = new THREE.MeshStandardMaterial({
    color: 'gray',
    map: texture,
    displacementMap: height,
    displacementScale: .6,
    alphaMap: alpha,
    transparent: true,
    depthTest: false
})

const plane = new THREE.Mesh(geometry, material)

// Mesh
scene.add(plane)
plane.rotation.x = 11.5
plane.rotation.z = 0

/*
const planeFolder = gui.addFolder("Plane")
planeFolder.add(plane.rotation, 'x').min(10.5).max(12).step(0.001)
planeFolder.add(plane.rotation, 'z').min(-5).max(5).step(0.001)
*/

// Lights

// key light
const keyLight = new THREE.PointLight(0x00b3ff, 0.1)
keyLight.position.set(.2,10,4.5)
keyLight.intensity = 2.5
scene.add(keyLight)

/*
const keyLightFolder = gui.addFolder("KeyLight")
keyLightFolder.add(keyLight.position, 'y').min(-3).max(3).step(0.01)
keyLightFolder.add(keyLight.position, 'x').min(-6).max(6).step(0.01)
keyLightFolder.add(keyLight.position, 'z').min(-3).max(3).step(0.01)
keyLightFolder.add(keyLight, 'intensity').min(0).max(10).step(0.01)

const keyLightColor = {
    color: 0x00b3ff
}

keyLightFolder.addColor(keyLightColor, 'color')
    .onChange(() => {
        keyLight.color.set(keyLightColor.color)
    })
*/

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth * .7,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth * .7
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 3
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
document.addEventListener('mousemove', animateTerrain)
let mouseY = 0

function animateTerrain(event) {
    mouseY = event.clientY
}

const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update objects
    plane.rotation.z = .1 * elapsedTime
    plane.material.displacementScale = .3 + mouseY * 0.0008

    // Update Orbital Controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()